import Card from "@mui/material/Card";
import SectionWrapper from "../../../../components/layout/SectionWrapper";
import styled from "../../../../util/styled";

const PREFIX = "FormationTestimonials";

export const classes = {
  container: `${PREFIX}-container`,
  grid: `${PREFIX}-grid`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  backgroundColor: "transparent",
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.grid}`]: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

export const Item = styled(Card)(({ theme }) => ({
  ...theme.typography.body1,
  height: 225,
  fontWeight: 600,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: 20,
}));
