import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import HeroSubtitle from "../../../../components/HeroSubtitle";
import HeroTitle from "../../../../components/HeroTitle";
import { globalClasses } from "../../../../components/layout/MainLayout/styles";
import { FORMATION_QUALITY_HERO_SECTION } from "../../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const QualityHero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper
      padding="normal"
      isMobile={isMobile}
      sectionId={FORMATION_QUALITY_HERO_SECTION}
      className={globalClasses.innoBlueGradient}
    >
      <div className={classes.background}>
        <Container>
          <Grid
            item
            sm={12}
            md={6}
            justifyContent={isMobile ? "center" : "flex-start"}
            container
          >
            <HeroTitle>
              Des formations de qualité au service de nos clients
            </HeroTitle>
            <HeroSubtitle>
              100 % des clients d'Innovance sont des associations ! Nous
              connaissons vos problématiques de gestion et par nos formations,
              nous avons à cœur de maintenir et développer les compétences pour
              vous aider à pérenniser vos actions.
            </HeroSubtitle>
          </Grid>
        </Container>
      </div>
    </StyledSectionWrapper>
  );
};

export default QualityHero;
