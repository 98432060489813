import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { PropsWithChildren } from "react";
import { StyledTypography } from "./styles";

const HeroSubtitle = (props: PropsWithChildren<any>) => {
  const { children } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledTypography
      variant="h6"
      align={isMobile ? "center" : "inherit"}
      gutterBottom
    >
      {children}
    </StyledTypography>
  );
};

export default HeroSubtitle;
