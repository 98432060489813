import Container from "@mui/material/Container";
import React from "react";
import SectionTitle from "../../../../components/layout/SectionTitle";
import {
  FORMATION_QUALITY_CERTIFICATIONS_SECTION,
  QUALIOPI_HREF,
} from "../../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const Certifications = () => {
  return (
    <StyledSectionWrapper sectionId={FORMATION_QUALITY_CERTIFICATIONS_SECTION}>
      <SectionTitle text="Certifications" variant="innoGrey" />
      <Container className={classes.container}>
        <a href={QUALIOPI_HREF} target="_blank">
          <img
            // className={classes.img}
            src="/images/qualiopi.png"
            alt="Qualiopi"
          />
        </a>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Certifications;
