import SectionWrapper from "../../../../components/layout/SectionWrapper";
import styled from "../../../../util/styled";

const PREFIX = "FormationIndicators";

export const classes = {
  container: `${PREFIX}-container`,
  gridWrapper: `${PREFIX}-gridWrapper`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.gridWrapper}`]: {
    margin: theme.spacing(5, 0),
  },
}));
