import { Divider } from "@mui/material";
import Container from "@mui/material/Container";
import React from "react";
import FeatureRow from "../../../../components/FeatureRow";
import SectionTitle from "../../../../components/layout/SectionTitle";
import {
  AGENDA_FEATURE_REPORT,
  FORMATION_COMPTA_CYCLE_INDICATORS,
  FORMATION_IA_CYCLE_INDICATORS,
  FORMATION_IB_CYCLE_INDICATORS,
  FORMATION_QUALITY_INDICATORS_SECTION,
} from "../../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";
import Button from "@mui/material/Button";
import { FaDownload } from "react-icons/fa";

const CycleTitle = ({
  fileName,
  title,
}: {
  title: string;
  fileName: string;
}) => {
  const handleClick = (fileName: string) => (e: any) => {
    const link = document.createElement("a");
    link.download = fileName;
    link.href = `/files/${fileName}`;
    link.click();
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {title}
      <Button
        startIcon={<FaDownload />}
        onClick={handleClick(fileName)}
        variant="text"
        size="medium"
        aria-label="Télécharger la fiche produit"
        style={{ width: 375, marginTop: 8 }}
      >
        Télécharger le programme de formation
      </Button>
    </div>
  );
};

const Indicators = () => {
  return (
    <StyledSectionWrapper sectionId={FORMATION_QUALITY_INDICATORS_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Indicateurs" variant="innoGrey" />
        <FeatureRow
          id={FORMATION_IA_CYCLE_INDICATORS}
          direction="column-reverse"
          title={
            <CycleTitle
              title="Cycle Innovance Agenda"
              fileName="programme-Formation-IA-str-ss-dates-202303.pdf"
            />
          }
          text="Rapport obtenu sur la base des enquêtes menées auprès des apprenants - Période du 01/01/2022 au 31/03/2023 - 4 sessions"
          imgSrc="/images/formation/qualite/indicateurs-qualite-cycle-innovance-agenda_2022.jpg"
          imgWidth="700"
          imgHeight="225"
        />
        <Divider />
        <FeatureRow
          id={FORMATION_IB_CYCLE_INDICATORS}
          direction="column-reverse"
          title={
            <CycleTitle
              title="Cycle Innovance Budget"
              fileName="programme-Formation-IB-str-ss-dates-202303.pdf"
            />
          }
          text="Rapport obtenu sur la base des enquêtes menées auprès des apprenants - Période du 01/01/2022 au 31/03/2023 - 7 sessions"
          imgSrc="/images/formation/qualite/indicateurs-qualite-cycle-innovance-budget_2022.jpg"
          imgWidth="700"
          imgHeight="225"
        />
        <Divider />
        <FeatureRow
          id={FORMATION_COMPTA_CYCLE_INDICATORS}
          direction="column-reverse"
          title="Cycle Innovance Agenda & Innovance Budget"
          text="Rapport obtenu sur la base des enquêtes menées auprès des apprenants - Période du 01/01/2022 au 31/03/2023 - 21 sessions"
          imgSrc="/images/formation/qualite/indicateurs-qualite-cycle-innovance-agenda-budget_2022.jpg"
          imgWidth="700"
          imgHeight="225"
        />
      </Container>
    </StyledSectionWrapper>
  );
};

export default Indicators;
