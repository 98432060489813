import SectionWrapper from "../../../../components/layout/SectionWrapper";
import styled from "../../../../util/styled";

const PREFIX = "FormationQualityHero";

export const classes = {
  root: `${PREFIX}-root`,
  background: `${PREFIX}-background`,
  typo: `${PREFIX}-typo`,
  actions: `${PREFIX}-actions`,
  xsActions: `${PREFIX}-xsActions`,
  button: `${PREFIX}-button`,
};

export const StyledSectionWrapper = styled(SectionWrapper)<{
  isMobile: boolean;
}>(({ theme, isMobile }) => ({
  height: 520,

  [`& .${classes.background}`]: {
    backgroundImage: !isMobile
      ? "url('/images/innovance-solutions-accompagnement-et-expertise.svg')"
      : "none",
    backgroundPosition: "85%",
    backgroundSize: "37%",
    backgroundRepeat: "no-repeat",
    backgroundClip: "padding-box",
    backgroundAttachment: "local",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },

  [`& .${classes.typo}`]: {
    color: theme.palette.common.white,
  },
}));
