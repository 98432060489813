import EuroIcon from "@mui/icons-material/Euro";
import Event from "@mui/icons-material/Event";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import React from "react";
import SectionTitle from "../../../../components/layout/SectionTitle";
import { FORMATION_QUALITY_INDICATORS_SECTION } from "../../../../util/constants";
import { classes, Item, StyledSectionWrapper } from "./styles";

const CalculatorIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M7,2H17A2,2 0 0,1 19,4V20A2,2 0 0,1 17,22H7A2,2 0 0,1 5,20V4A2,2 0 0,1 7,2M7,4V8H17V4H7M7,10V12H9V10H7M11,10V12H13V10H11M15,10V12H17V10H15M7,14V16H9V14H7M11,14V16H13V14H11M15,14V16H17V14H15M7,18V20H9V18H7M11,18V20H13V18H11M15,18V20H17V18H15Z" />
    </SvgIcon>
  );
};

type FormationModuleType = "agenda" | "budget" | "compta";
interface FormationTestimonialItem {
  text: string;
  module: FormationModuleType;
}

const items: FormationTestimonialItem[] = [
  {
    text: "« J'ai apprécié la clarté et les réponses aux questions individuelles tout en déroulant le programme ainsi qu'un groupe restreint en nombre. »",
    module: "agenda" as FormationModuleType,
  },
  {
    text: "« Je tiens à souligner le dynamisme, la pertinence et la disponibilité de la formatrice. »",
    module: "agenda" as FormationModuleType,
  },
  {
    text: "« Les modules courts et en distanciels sont appréciables. »",
    module: "agenda" as FormationModuleType,
  },
  {
    text: "« C'est une formation très concrète avec une bonne formatrice, captivante et qui maîtrise très bien son sujet. »",
    module: "agenda" as FormationModuleType,
  },
  {
    text: "« J'ai trouvé la formatrice très professionnelle et à l'écoute des stagiaires et j'ai apprécié que les thèmes soient abordés dans le détail. »",
    module: "budget" as FormationModuleType,
  },
];

const AgendaAvatar = () => {
  return (
    <Avatar sx={{ backgroundColor: (theme) => theme.palette.iaBlue.main }}>
      <Event />
    </Avatar>
  );
};
const BudgetAvatar = () => {
  return (
    <Avatar sx={{ backgroundColor: (theme) => theme.palette.ibPurple.main }}>
      <EuroIcon />
    </Avatar>
  );
};
const ComptaAvatar = () => {
  return (
    <Avatar sx={{ backgroundColor: (theme) => theme.palette.ibGreen.main }}>
      <CalculatorIcon />
    </Avatar>
  );
};

const Testimonials = () => {
  return (
    <StyledSectionWrapper sectionId={FORMATION_QUALITY_INDICATORS_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Avis" variant="innoGrey" />
        <Grid
          className={classes.grid}
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {items.map((item, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <Item>
                <CardHeader
                  avatar={
                    item.module === "agenda" ? (
                      <AgendaAvatar />
                    ) : item.module === "budget" ? (
                      <BudgetAvatar />
                    ) : (
                      <ComptaAvatar />
                    )
                  }
                  subheader={`Cycle ${item.module}`}
                />
                <CardContent>{item.text}</CardContent>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Testimonials;
